import type { DateTimeFormatter } from 'kitchen/types'
import { getDateTimeFormatter } from 'kitchen/utils/native-date'
import { getLocale } from './get-locale'

const OPTIONS = {
  short: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  numeric: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
} as const

export type DateFormatStyle = keyof typeof OPTIONS

export function getDateFormat(
  style: DateFormatStyle = 'short',
  timeZone: string | undefined = undefined
): DateTimeFormatter {
  return getDateTimeFormatter(getLocale(), { ...OPTIONS[style], timeZone })
}
